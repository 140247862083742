@import "../../styles/constants.scss";

$min-height: 400px;

.dual-timelines {
  max-height: 800px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 16px;
  position: relative;
  min-height: $min-height;

  .dual-timelines-loading {
    align-self: center;
    display: flex;
    position: absolute;
    top: 50%;
  }

  .legend-date-text-container {
    padding: 1.5rem 0rem 2rem;
    font-size: $default-font-size;
  }

  .zoom-controller-container {
    display: flex;
    justify-content: flex-end;
  }

  .legend-date-option-container {
    padding-bottom: 70px;

    .legend-date-option {
      background-color: $color-grey;
      border-radius: $default-button-radius;
      color: $color-dark-grey;
      cursor: pointer;
      display: inline-block;
      font-size: $default-font-size;
      padding: 8px 20px;
      margin: 0rem 0.5rem 0.5rem 0rem;
      white-space: nowrap;
      width: fit-content;
    }

    .legend-date-option.selected {
      @include date-text-display();
      cursor: pointer;
      font-size: $default-font-size;
      padding: 8px 20px;
    }
  }

  .error-message {
    height: 100%;
    width: 100%;
    display: flex;
    margin-bottom: calc($min-height / 2.5);
    align-items: center;
    justify-content: center;
  }

  .dual-timelines-timeline-container {
    > :first-child {
      margin-bottom: 38px;
    }

    > :only-child {
      margin-bottom: 0px;
    }
  }
}
