@import "../../styles/constants.scss";

.card-wrapper {
  background: $color-white;
  border-radius: $default-card-radius;
  box-shadow: 2px 10px 20px 2px rgba(0, 0, 0, 0.1);
  height: fit-content;

  .inner-card-wrapper {
    height: fit-content;

    .card-content {
      padding: $default-card-padding;
      border-radius: $default-card-radius;

      .hanks-tip-container-wrapper {
        float: right;
        position: relative;
        z-index: 2;
      }
    }
  }
}
