@import "../../styles/constants.scss";

$cell-padding: 10px;
$cell-left-padding: 20px;

.user-comparison-table-container {
  overflow-x: unset !important;

  .user-comparison-table {
    .user-column-header {
      font-weight: $font-weight-bold;

      .user-id-field {
        font-weight: $default-font-weight;
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;

        // hide scrollbar
        scrollbar-width: none;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Internet Explorer 10+ */

        @media (max-width: 1199px) {
          max-width: 120px;
        }
      }
    }

    th {
      background-color: $color-black;
      border-left: 1px solid $color-mid-light-grey;
      font-size: $small-font-size;
      padding: $cell-padding;
      padding-left: $cell-left-padding;
    }

    th:nth-child(1),
    &.with-initial-user th:nth-child(2) {
      border-left: none;
    }

    tr:nth-child(odd) {
      background-color: $color-white;
    }

    tr:nth-child(even) {
      background-color: $color-grey;
    }

    &.with-initial-user td:nth-of-type(2) {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }

    tr:nth-last-child(2) {
      background-color: $color-white !important;
    }

    &.with-initial-user tr:nth-last-child(2) > td:nth-of-type(2) {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }

    tr:last-child {
      background-color: $color-white !important;
      &:last-child {
        td:not(:first-child) {
          padding-left: $cell-padding;
        }
      }
    }

    &.with-initial-user tr:last-child > td:nth-of-type(2) {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }

    td {
      border: none;
      font-size: $small-font-size;
      padding: $cell-padding;
      padding-left: $cell-left-padding;

      &:not(:first-child) {
        border-left: 1px solid $color-mid-light-grey;
      }
    }

    .user-link-button {
      background-color: $color-purple;
      border: none;
      border-radius: 16px;
      color: $color-white;
      cursor: pointer;
      font-size: $small-font-size;
      padding: 7px 12px;
    }

    .user-checkbox-label {
      cursor: pointer;
    }
  }
}
