@import "../../styles/constants.scss";

$increment-decrement-border-radius: 8px;

.increment-controller {
  display: flex;
  align-items: center;

  label {
    font-weight: bold;
    margin-right: 10px;
  }

  .icons {
    display: flex;

    .decrement-and-increment-container {
      display: flex;
      flex-direction: row;
      margin-right: 20px;

      .icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: $color-purple;
        width: 30px;
        height: 25px;

        svg {
          stroke-linecap: round;
          stroke-width: 1.7px;
          // To center the icons vertically
          position: relative;
          top: 0.5px;

          path {
            stroke: $color-white;
          }
        }
      }

      .icon-container.disabled {
        cursor: inherit;
        background-color: $color-light-purple;
      }

      .icon-container.decrement-container {
        border-radius: $increment-decrement-border-radius 0px 0px
          $increment-decrement-border-radius;
      }

      .icon-container.increment-container {
        border-radius: 0px $increment-decrement-border-radius
          $increment-decrement-border-radius 0px;
        margin-left: 1px;
      }
    }

    // reset icon has its own styling
    .icon-container.reset-container {
      display: flex;
      align-items: center;
      margin-right: 10px;

      svg {
        cursor: pointer;
        stroke-width: 0px;

        path {
          fill: $color-purple;
        }
      }
    }

    .icon-container.reset-container.disabled > svg {
      cursor: inherit;

      path {
        fill: $color-light-purple;
      }
    }
  }
}
