@import "../../styles/constants.scss";

$pie-chart-height: 300px;
$legend-icon-size: 20px;

.pie-chart-container {
  color: $color-dark-grey;
  position: relative;

  .pie-chart-updating {
    display: flex;
    height: 100%;
    position: absolute;
    top: -8px;
    width: 100%;
  }

  .pie-chart-title {
    font-size: $large-header-font-size;
    font-weight: $font-weight-bold;
    letter-spacing: $default-letter-spacing;
  }

  .pie-chart-dropdown {
    padding-top: 2rem;
  }

  .pie-chart-content {
    .pie-chart-chart-wrapper {
      height: $pie-chart-height;
    }

    .pie-chart-legend-wrapper {
      .pie-chart-legend {
        font-size: $small-font-size;
        width: 100%;

        .pie-chart-legend-col-wrapper {
          .pie-chart-legend-row {
            padding: 8px;

            .pie-chart-legend-row-color {
              height: $legend-icon-size;
              position: absolute;
              vertical-align: middle;
              width: $legend-icon-size;
            }

            .pie-chart-legend-row-text {
              padding-left: calc($legend-icon-size + 5px);
              vertical-align: middle;
              word-wrap: break-word;
            }
          }
        }
      }
    }
  }

  .pie-chart-content.vertical {
    .pie-chart-chart-wrapper {
      width: 100%;
    }

    .pie-chart-legend-wrapper {
      width: 100%;

      .pie-chart-legend-col-wrapper {
        display: inline-block;
        vertical-align: top;
        width: 50%;
      }

      .pie-chart-legend-col-wrapper.single-col {
        width: 100%;
      }
    }
  }

  .pie-chart-content.horizontal {
    position: relative;
    height: 100%;

    .pie-chart-chart-wrapper {
      display: inline-block;
      width: 50%;
    }

    .pie-chart-legend-wrapper {
      display: inline-block;
      height: 100%;
      position: absolute;
      width: 50%;

      .pie-chart-legend {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .pie-chart-centered-option {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
