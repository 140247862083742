@import "../../styles/constants.scss";

.label-song-container {
  .label-song-logout {
    float: right;
    margin: 1rem;
  }

  .label-song-footer {
    padding-bottom: 10px;
  }
}
