@import "../../styles/constants.scss";

$component-min-height: 419px;
$secondary-metric-padding: 5px;

.artist-users-wrapper {
  min-height: $component-min-height;

  .artist-users-loader {
    height: $component-min-height;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .total-users-section {
    color: $color-dark-grey;
    font-weight: $font-weight-bold;
    letter-spacing: $default-letter-spacing;

    .total-users-label {
      font-size: $large-header-font-size;
      line-height: 32px;
      padding-bottom: 1rem;
    }

    .total-users-value {
      font-size: $xxlarge-header-font-size;
      line-height: 48px;
    }
  }

  .artist-users-secondary-metrics {
    color: $color-mid-grey;
    font-weight: $font-weight-bold;
    letter-spacing: $default-letter-spacing;
    padding: 4rem 0rem;

    .artist-users-secondary-metric {
      display: inline-block;
      vertical-align: top;
      width: calc(50% - $secondary-metric-padding);
      overflow-wrap: break-word;

      .secondary-metric-label {
        font-size: $default-header-font-size;
        line-height: $header-line-height;
      }

      .secondary-metric-value {
        font-size: $xlarge-header-font-size;
        line-height: 48px;
      }
    }

    .artist-users-secondary-metric.left {
      padding-right: $secondary-metric-padding;
    }

    .artist-users-secondary-metric.right {
      padding-left: $secondary-metric-padding;
    }
  }

  .percentile-metric-wrapper {
    padding-bottom: 1rem;
  }
}
