@import "../../styles/constants.scss";

.label-history-table-container {
  .label-history-table-header {
    background-color: $color-header-dark-grey;

    .label-history-table-header-cell {
      color: $color-white;
      font-weight: bold;

      .header-cell-sort-label {
        color: $color-white;

        svg {
          color: $color-light-grey;
        }
      }
    }
  }

  .cta-button {
    @include purple-button();
  }
}
