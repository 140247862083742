$hover-z-index: 12;

#timeline-tooltip {
  height: fit-content;
  width: fit-content;
  z-index: $hover-z-index;
  pointer-events: none;
  position: fixed;
  display: flex;
  justify-content: center;

  .timeline-value {
    padding-left: 0.5rem;
  }

  .selected {
    font-weight: bold;
  }

  .timeline-tooltip-close-icon {
    // 0px width and height to keep from interfering with tooltip placement
    height: 0px;
    width: 0px;
    cursor: pointer;
    pointer-events: auto;
    position: relative;
    left: -34px;
    top: 10px;
  }

  .timeline-tooltip-close-icon.hidden {
    // For the close icon
    visibility: hidden;
  }
}

#timeline-tooltip.hidden {
  // Visibility instead of 'display: none' is important for repositioning logic
  visibility: hidden;
}

#timeline-tooltip.visible.sticky {
  // Ensures it appears underneath the hover timeline tooltip
  // While still appearing above the Hank's Tips icons
  z-index: $hover-z-index - 1;
}
