@import "../../styles/constants.scss";

$component-min-height: 250px;
$component-extra-text-extra-height: 70px;

.metric-card-container {
  color: $color-dark-grey;
  letter-spacing: $default-letter-spacing;
  min-height: $component-min-height;
  position: relative;

  &.with-extra-text {
    min-height: calc(
      $component-min-height + $component-extra-text-extra-height
    );
    .metric-score {
      top: calc(50% - ($component-extra-text-extra-height / 2));
    }
  }

  .metric-header-title {
    font-size: $large-header-font-size;
    font-weight: $font-weight-bold;
  }

  .metric-score {
    font-size: 60px;
    font-weight: $font-weight-bold;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    word-break: break-all;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
  }

  .metric-secondary-score-container {
    bottom: 0;
    height: fit-content;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;

    .metric-secondary-scores {
      height: fit-content;

      .metric-secondary-score {
        display: inline-block;
        font-size: $default-font-size;
        text-align: center;
      }
    }

    .metric-extra-text {
      padding-top: 40px;
      font-weight: $font-weight-bold;
    }
  }

  .metric-secondary-score.left {
    padding-right: 7px;
  }

  .metric-secondary-score.right {
    padding-left: 7px;
  }

  .metric-card-loader {
    padding-top: 6rem;
  }
}
