@import "../../styles/constants.scss";

.label-history-container {
  .label-history-header {
    align-items: center;
    background-color: $color-grey;
    display: flex;
    height: 120px;
    justify-content: space-between;

    padding: 0% $default-page-width-padding;

    @media (max-width: 1199px) {
      padding: 0% $small-page-width-padding;
    }

    .label-history-back-button {
      background-color: $color-white;
      border-radius: 50%;
      padding: 3px 5px;

      .back-button-icon {
        color: $color-purple;
        cursor: pointer;
        vertical-align: middle;
        width: 20px;
      }
    }

    .logout-button-container {
      display: flex;
    }
  }

  .label-history-body {
    margin: 0% $default-page-width-padding $default-page-height-padding;

    @media (max-width: 1199px) {
      margin: 0% $small-page-width-padding $default-page-height-padding;
    }

    .page-title-container {
      display: flex;
      justify-content: space-between;
      padding: 3rem 0rem;

      .page-title {
        align-items: center;
        display: flex;
        font-weight: bold;
        font-size: 28px;
        white-space: nowrap;
      }

      .search-bar-container {
        align-items: center;
        display: flex;
        width: 45%;
      }
    }

    .page-body-container {
      padding-bottom: 3rem;

      .date-accordion-container {
        border: none;
        box-shadow: none;
        padding-bottom: 1.5rem;

        &::before {
          background-color: transparent;
        }

        .date-accordion-button {
          background-color: $color-grey;

          .date-accordion-title {
            font-weight: bold;
          }

          .date-accordion-icon {
            color: $color-purple;
          }
        }

        .date-accordion-button.open {
          border-top-left-radius: $default-card-radius;
          border-top-right-radius: $default-card-radius;
        }

        .date-accordion-button.closed {
          border-radius: $default-card-radius;
        }

        .date-accordion-content {
          padding: 0;
        }
      }

      .no-data-available {
        padding-top: 4rem;
        text-align: center;
      }
    }
  }
}
