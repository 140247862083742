@import "../../styles/constants.scss";

.dashboard-footer-container {
  display: flex;
  align-items: center;
  font-size: $default-font-size;
  justify-content: space-between;
  
  .build-version-label {
    color: #747079
  }

  a {
    color: $color-purple;
    text-decoration: none;
  }
}
