@import "../../styles/constants.scss";

.logout-button {
  background-color: inherit;
  border: none;
  border-radius: $default-button-radius;
  color: $color-purple;
  cursor: pointer;
  font-weight: $font-weight-slight-bold;
  font-size: $default-font-size;
  line-height: $header-line-height;
  padding: 7px 16px;

  &:hover {
    background-color: $color-lighter-light-grey;
  }
}

.logout-button.light {
  background-color: $color-white;
}
