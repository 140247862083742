@import "../../styles/constants.scss";

$min-height: 100px;

.timeline-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-flow: column;
  width: 100%;
  min-height: $min-height;

  .timeline-type {
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;

    .timeline-title {
      font-size: $large-header-font-size;
      letter-spacing: $default-letter-spacing;
      font-weight: $font-weight-bold;
      line-height: 32px;
      white-space: nowrap;
      margin: 0px;
      margin-bottom: 12px;
    }

    .timeline-dropdowns {
      display: flex;
      justify-content: right;
      align-items: center;
      margin-bottom: 10px;

      // Targets Safari V11+
      // From https://www.browserstack.com/guide/create-browser-specific-css
      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) and (stroke-color: transparent) {
          width: fit-content;
        }
      }
    }

    .timeline-dropdown-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      .select-filter-dropdown {
        display: flex;
        height: fit-content;
      }

      .timeline-label-container {
        width: fit-content;
        height: 100px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }

  .timeline-no-data-message {
    height: 100%;
    margin-top: calc($min-height / 2);
  }

  .timeline::-webkit-scrollbar {
    // Hide scrollbar for webkit
    width: 0px;
    height: 0px;
  }

  .timeline {
    position: relative;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
    width: 100%;
    // For horizontal scroll
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;

    // From https://stackoverflow.com/questions/16670931/hide-scroll-bar-but-while-still-being-able-to-scroll
    // To hide non-webkit scrollbars
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    .timeline-scroll {
      width: 100%;
    }

    .loader {
      position: absolute;
      z-index: 3;
      pointer-events: none;
    }

    .timeline-canvas-stage {
      position: absolute;
      z-index: 2;
    }
  }
}
