@import "../../styles/constants.scss";

$close-button-width: 35px;
$background-header-height: 74px;
$popup-message-height: 20px;
$popup-message-padding: 8px;

.label-history-open-tabs-header-container {
  height: $background-header-height;
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 20;
  // For transition animation
  overflow: hidden;
  transition: all 0.4s ease-in-out;

  .label-history-open-tabs-header-background {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: $background-header-height;
    background-color: $color-header-dark-grey;

    .label-history-left {
      margin-left: $default-page-width-padding;
      display: flex;
      align-items: center;

      .label-history-number-selected {
        margin-left: 20px;
        color: $color-white;
      }

      .label-history-close-button {
        height: $close-button-width;
        width: $close-button-width;
        border-radius: $close-button-width;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $color-neutral-grey-3;
        cursor: pointer;

        svg {
          color: $color-white;
        }
      }
    }

    .cta-button {
      @include purple-button();
      margin-right: $default-page-width-padding;
    }

    // Match the breakpoint styling of the 'label-history.scss' in 'views'
    @media (max-width: 1199px) {
      .label-history-left {
        margin-left: $small-page-width-padding;
      }

      .cta-button {
        margin-right: $small-page-width-padding;
      }
    }
  }

  .label-history-open-tabs-popup-message {
    display: none;
  }
}

.label-history-open-tabs-header-container.hidden {
  height: 0px;
  overflow: hidden;
}

.label-history-open-tabs-header-container.popup-message {
  .label-history-open-tabs-popup-message {
    align-content: center;
    background-color: red;
    color: $color-black;
    display: flex;
    font-size: 13px;
    height: $popup-message-height;
    justify-content: center;
    padding: $popup-message-padding 0px;
    width: 100%;
  }
}

.label-history-open-tabs-header-container.popup-message.visible {
  height: $background-header-height + ($popup-message-padding * 2) +
    $popup-message-height;
}
