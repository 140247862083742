@import "../../styles/constants.scss";

$top-bottom-padding: 10px;

.card-fraud-reason {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: $top-bottom-padding 0px;

  .card-fraud-reason-text {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    text-align: left;

    &.with-textarea {
      margin-top: 8px;
    }

    user-select: none;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */

    .checkbox.card-fraud-checkbox {
      background-color: $color-white;
    }

    label {
      align-items: center;
      cursor: pointer;
      display: flex;
      font-size: $default-font-size;
      margin-left: 5px;
    }
  }

  .card-fraud-description {
    display: flex;
    align-items: center;
    font-size: $small-font-size;
    color: $color-black;
  }

  .textarea-container {
    flex-grow: 1;

    .textarea {
      @include fraud-text-area();
      width: calc(100% - 36px);
      margin-left: 12px;
      height: 78px;
      font-size: $default-font-size;

      &.disabled {
        border-color: $color-mid-grey;
      }

      &::-webkit-input-placeholder {
        color: $color-mid-grey;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        color: $color-mid-grey;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $color-mid-grey;
      }

      &:-ms-input-placeholder {
        color: $color-mid-grey;
      }

      &::placeholder {
        color: $color-mid-grey;
      }
    }
  }

  &.row {
    max-width: max-content;

    &:not(:last-child) {
      margin-right: 30px;
    }
  }
}
