@import "../../styles/constants.scss";

$top-bottom-padding: 10px;

.card-fraud-reasons-container {
  background-color: $color-grey;
  color: #4d4a53;
  text-align: center;
  border-radius: 0px 0px $default-card-radius $default-card-radius;
  padding: 1rem;
  padding-top: calc($top-bottom-padding / 2);
  padding-bottom: calc($top-bottom-padding / 2);

  h4 {
    display: flex;
    margin: 12px 0px;
  }

  .card-fraud-reasons {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
  }
}
