@import "../../styles/constants.scss";

.artist-header-wrapper {
  align-items: flex-end;
  color: $color-dark-grey;
  display: flex;
  padding-top: 5rem;

  .artist-header-text {
    display: inline-block;
    font-size: 26px;
    font-weight: $font-weight-bold;
    letter-spacing: $default-letter-spacing;
  }

  .artist-header-hanks-tip {
    display: inline-block;
    padding-left: 1rem;
  }
}
