@import "../../styles/constants.scss";

.similar-users-button {
  @include purple-button();
  margin-top: 40px;
}

.similar-users-button.disabled {
  @include purple-button-disabled();
}

.similar-users-hover-overlay {
  @include info-tool-tip();
  font-size: $small-font-size;
  position: fixed;
  z-index: 10;
}
