@import "../../styles/constants.scss";

.scroll-bar {
  margin: 30px 0px 20px;
  height: 10px;
  left: 0px;
  bottom: 0px;
  border-radius: $default-button-radius;
  background-color: $color-grey;
  visibility: hidden;

  .scroll-thumb {
    cursor: pointer;
    z-index: 10;
    position: relative;
    background-color: $color-purple;
    border-radius: $default-button-radius;
    height: 100%;
  }

  // Toggle visibility instead of display to ensure components don't move
  &.visible {
    visibility: visible;
  }
}
