@import "../../styles/constants.scss";

$rounded-checkmark-path: "M 7.038 9.46 L 5.409 7.76 C 5.146 7.485 4.875 7.101 4.53 6.938 C 3.914 6.647 3.148 7.017 3.04 7.732 C 2.942 8.388 3.405 8.772 3.805 9.189 L 5.564 11.025 C 5.864 11.337 6.152 11.718 6.494 11.975 C 6.898 12.275 7.489 12.217 7.84 11.859 C 8.372 11.319 8.819 10.65 9.295 10.053 L 12.162 6.464 C 12.658 5.847 13.539 5.081 13.056 4.198 C 12.716 3.574 11.854 3.498 11.389 4.01 C 10.527 4.958 9.768 6.029 8.967 7.031 C 8.332 7.829 7.599 8.604 7.038 9.46 Z";

.checkbox {
  // For customized checkbox appearance
  display: grid;
  margin-right: 5px;
  height: fit-content;
  width: fit-content;

  input[type="checkbox"] {
    grid-column: 1;
    grid-row: 1;
    appearance: none;
    cursor: pointer;
    border: 1px solid $color-black;
    margin: 0px;
    background-color: inherit;
  }

  // Color the checkboxes when selected
  input[type="checkbox"]:checked {
    background-color: $color-purple;
    border-color: $color-purple;
  }

  // Custom, rounded checkmark rather than default
  .checkmark {
    pointer-events: none;
    grid-column: 1;
    grid-row: 1;
    background-color: $color-white;
    clip-path: path($rounded-checkmark-path);
  }
}
