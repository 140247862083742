@import "../../styles/constants.scss";

.not-found-page {
  text-align: center;
  margin: 250px auto auto;

  @media (max-width: $sm-mobile-breakpoint) {
    margin: 50% auto auto;
  }

  .not-found-404 {
    font-size: 10rem;
    font-weight: bold;

    @media (max-width: $sm-mobile-breakpoint) {
      font-size: 8rem;
    }
  }

  .not-found-text {
    font-size: 3rem;
  }
}
