@import "../../styles/constants.scss";

.hanks-tip-container {
  position: relative;

  .hanks-tip-icon {
    svg {
      cursor: pointer;
      vertical-align: middle;
    }
  }

  .hanks-tip-text {
    @include info-tool-tip();
    font-size: $default-font-size;
    margin-top: 8px;
    z-index: 10;

    :not(:first-child) {
      // Display proper line breaks
      margin-top: 1em;
    }
  }
}
