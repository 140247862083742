.loader {
  margin: auto;
  border: 0.5rem solid #eaf0f6;
  border-radius: 50%;
  border-top: 0.5rem solid #006ee6;
  width: 3rem;
  height: 3rem;
  -webkit-animation: spinner 4s linear infinite;
  animation: spinner 4s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
