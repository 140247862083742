@import "../../styles/constants.scss";

$icon-width: 16px;
$tool-tip-width: 200px;

.total-plays-card {
  display: flex;
  min-height: 370px;

  .total-plays-card-content {
    display: flex;
    flex-direction: column;
    width: 100%;

    .total-plays-header-title {
      display: flex;
      align-items: center;
    }

    .total-plays-header-title {
      font-size: 18px;
      font-weight: bold;
      padding-top: 0.5rem;
      white-space: nowrap;
    }

    .monetized-plays-score {
      font-weight: bold;
    }

    .total-plays-text {
      color: $color-neutral-grey-3;
      font-size: $default-font-size;
    }

    .total-plays-progress-bar {
      margin: 1rem 0rem 2rem;
    }

    .progress-bar-tool-tip {
      @include info-tool-tip();
      padding: 6px 12px;
      position: fixed;
      font-size: $small-font-size;
      z-index: 10;
    }

    .total-plays-score {
      font-size: 3rem;
      font-weight: bold;
    }
  }

  .loader {
    align-self: center;
    justify-self: center;
  }
}
