@import "../../styles/constants.scss";

.percentile-metric-container {
  color: $color-dark-grey;

  .percentile-metric-label {
    align-items: center;
    display: flex;
    font-weight: $font-weight-bold;
    font-size: $default-header-font-size;
    letter-spacing: $default-letter-spacing;
    justify-content: space-between;
    z-index: 2;

    .header-sub-section {
      display: inline-block;
    }

    .percentile-metric-info-icon {
      display: flex;
      align-items: center;
    }
  }

  .divider {
    background: transparent;
    border-right: 2px dashed $color-light-grey;
    height: 60px;
    margin-top: -14px;
    position: absolute;
  }
}

.percentile-metric-container.stacked-container {
  .percentile-metric-label {
    padding-bottom: 1.5rem;
  }

  .stacked-content-wrapper {
    align-items: center;
    display: flex;
    position: relative;

    .stacked-content {
      display: inline-block;
      position: relative;

      .percentile-metric-slider-wrapper {
        margin-right: 10px;
        position: relative;
        z-index: 1;
      }

      .percentile-metric-subscript {
        display: inline-block;
      }
    }
  }
}

.percentile-metric-container.inline-container {
  display: flex;
  justify-content: space-between;

  .percentile-metric-slider-wrapper {
    align-items: center;
    display: flex;
    margin: 0px 10px;
    z-index: 1;

    .percentile-metric-slider {
      width: 100%;
    }
  }

  .percentile-metric-subscript {
    display: flex;
    align-items: center;
  }
}

.slider-info {
  background-color: $color-dark-grey;
  border-radius: 6px;
  color: $color-white;
  padding: 0.5rem;
  position: absolute;
  z-index: 2;
}
