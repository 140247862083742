@import "../../styles/constants.scss";

.percentile-metrics-container {
  display: flex;
  flex-direction: column;

  .percentile-metrics-title {
    color: $color-dark-grey;
    font-size: $large-header-font-size;
    font-weight: $font-weight-bold;
    letter-spacing: $default-letter-spacing;
    margin-bottom: 1rem;
  }

  .percentile-metrics-row {
    padding: 12px 0px;
  }
}
