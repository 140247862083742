@import "../../styles/constants.scss";

.info-tip-container {
  display: inline-block;
  font-weight: $default-font-weight;
  font-size: $default-header-font-size;
  padding-left: 3px;
  position: relative;
  vertical-align: middle;

  .info-icon {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .info-text {
    @include info-tool-tip();
    text-align: left;
    top: 0px;
    width: 250px;
    white-space: normal;
    z-index: 10;

    :not(:first-child) {
      // Display proper line breaks
      margin-top: 0.5em;
    }
  }

  // Adds the little arrow
  .info-text.arrow {
    transform: translateY(-50%);
    top: 50%;
  }

  .info-text.arrow::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent $color-dark-grey transparent transparent;
  }

  // Aligns the bottom of the tooltip instead of the top to the info icon
  .info-text.bottom-align {
    margin-top: -114px;
  }

  & svg {
    cursor: pointer;
    width: 18px;
  }
}
