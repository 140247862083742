@import "../../styles/constants.scss";

$min-component-height: 418px;
$percentile-metric-max-width: 500px;

.artist-amplitude-events-wrapper {
  color: $color-dark-grey;
  font-weight: $font-weight-bold;
  letter-spacing: $default-letter-spacing;
  min-height: $min-component-height;

  .artist-amplitude-events-header {
    font-size: $large-header-font-size;
    padding-bottom: 1.5rem;
  }

  .artist-amplitude-events-header.loading {
    padding-bottom: 5.5rem; // hehe
  }

  .artist-amplitude-events-percentage {
    font-size: $xxlarge-header-font-size;
    padding: 1rem 0rem 2rem;
    word-wrap: break-word;
  }

  .artist-amplitude-events-sub-header {
    font-size: $default-header-font-size;
    padding: 1.5rem 0rem;

    .not-section {
      color: $red-metric-color;
    }
  }

  .artist-amplitude-events-percentile-metric {
    font-weight: $default-font-weight;
    padding: 4rem 0rem 0.5rem;
    width: min(100%, $percentile-metric-max-width);
  }
}
