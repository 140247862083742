@import "../../styles/constants.scss";

$linked-users-pill-background-color: #ecebf0;
$linked-users-close-icon-width: 16px;

.linked-users-container {
  p {
    font-size: $default-header-font-size;
    font-weight: $font-weight-bold;
    line-height: 20px;
  }

  .linked-users {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    font-size: $default-font-size;

    .linked-user {
      width: fit-content;
      display: flex;
      align-items: center;
      white-space: nowrap;
      font-size: calc($default-font-size - 1px);
      background-color: $linked-users-pill-background-color;
      border-radius: $default-button-radius;
      padding: 3px 14px;
      margin: 0px 8px 8px 0px;

      svg {
        cursor: pointer;
        margin-left: 12px;
        width: $linked-users-close-icon-width;
      }
    }
  }
}
