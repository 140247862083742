@import "../../styles/constants.scss";

$radio-button-diameter: 24px;
$radio-border-width: 1px;
$radio-selected-inner-circle-padding: 4.5px;
$radio-selected-button-diameter: calc(
  $radio-button-diameter - ($radio-selected-inner-circle-padding * 2)
);

.selection-radio-buttons-container {
  display: flex;
  flex-direction: row;
  width: 100%;

  .selection-radio-button-wrapper {
    align-items: center;
    color: $color-black;
    display: flex;
    flex-direction: row;
    margin-right: 38px;
    margin-top: 18px;
    padding-right: 42px;

    label {
      padding: 0px 8px;
    }

    .selection-radio-button {
      // Create unselected radio button styling
      border: $radio-border-width solid $color-black;
      border-radius: 50%;
      cursor: pointer;
      height: $radio-button-diameter;
      width: $radio-button-diameter;

      &.selected {
        // Make selected radio button styling changes
        background-clip: content-box;
        background-color: $color-purple;
        border-color: $color-purple;
        height: $radio-selected-button-diameter;
        padding: $radio-selected-inner-circle-padding;
        width: $radio-selected-button-diameter;
      }
    }
  }
}
