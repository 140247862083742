/* app colors */

// light and dark shades
$color-white: #ffffff;
$color-off-white: #f4f2f6;
$color-grey: #f3f3f4;
$color-button-grey: #edebf0;
$color-lighter-light-grey: #dbd9de;
$color-mid-light-grey: #c4c4c4;
$color-light-grey: #c0bdc4;
$color-mid-grey: #87838c;
$color-dark-grey: #37333d;
$color-header-dark-grey: #363537;
$color-neutral-grey-3: #030303;
$color-black: #000000;

// reds
$color-light-red: #ff8080;

// pinks
$color-pink: #ed2783;

// purps
$color-purple: #772edd;
$color-light-purple: #cfc0e3;
$color-mid-purple: #b99be1;

// blues
$color-sky-blue: #a5daf5;
$color-light-blue: #dcdcff;
$color-mid-blue: #3d9eed;
$color-dark-blue: #4238b5;

// decision box slider colors
$decision-box-green: #c3e4c7;
$decision-box-light-green: #d8ead0;
$decision-box-yellow: #e6ead0;
$decision-box-dark-yellow: #efe8d0;
$decision-box-orange: #f2ded2;
$decision-box-red: #f0c8c9;
$decision-box-dark-red: #e7c2c4;

$default-font-weight: 400;
$font-weight-slight-bold: 500;
$font-weight-bold: 700;

$default-button-radius: 20px;
$default-card-radius: 8px;
$default-card-padding: 1.5rem;

$default-page-width-padding: 10%;
$default-page-height-padding: 3%;
$small-page-width-padding: 8%;
$dashboard-small-page-width-breakpoint: 1535px;

$default-letter-spacing: -0.02em;
$header-line-height: 24px;

$default-header-font-size: 16px;
$large-header-font-size: 20px;
$xlarge-header-font-size: 40px;
$xxlarge-header-font-size: 80px;

$default-font-size: 14px;
$small-font-size: 12px;
$width-of-a-space: 0.25em;

// metric limit colors
$default-metric-color: $color-dark-grey;
$yellow-metric-color: #ffa400;
$red-metric-color: #e31a1c;
$dark-red-metric-color: #b70002;

// plays categories colors
$total-plays-color: #dadada;
$monetized-plays-color: $color-sky-blue;
$suspicious-plays-color: $color-purple;
$legible-purple: #a278df;

// misc measurements
$hanks-tip-radius: 15px;
$md-mobile-breakpoint: 899px;
$sm-mobile-breakpoint: 599px;
$decision-box-reason-column-margin: 25px;

@mixin chart-tool-tip() {
  display: flex;
  align-items: left;
  flex-direction: column;
  background: $color-white;
  border: 1px solid black;
  padding: 1rem;
  width: 100%;
}

@mixin artist-chart-legend-icon {
  border-radius: 50%;
  display: inline-block;
  height: 12px;
  margin-right: 5px;
  width: 12px;
}

@mixin info-tool-tip() {
  background-color: $color-dark-grey;
  border-radius: 10px;
  color: $color-white;
  line-height: 20px;
  padding: 1rem;
  position: absolute;
}

@mixin fraud-text-area() {
  resize: none;
  padding: 5px;
  padding-left: calc(5px + $width-of-a-space);
  border-radius: 0px;
  border: 1px solid $color-black;
  font-family: inherit;
}

@mixin date-text-display() {
  color: $color-white;
  background-color: $color-purple;
  border-radius: $default-button-radius;
  padding: 5px 20px;
}

@mixin purple-button() {
  background-color: $color-purple;
  border: none;
  border-radius: $default-button-radius;
  color: $color-white;
  padding: 10px 25px;
  cursor: pointer;
}

@mixin purple-button-disabled() {
  background-color: $color-light-purple;
  pointer-events: none;
  cursor: auto;
}

/* export variables needed in js code */
:export {
  defaultMetricColor: $default-metric-color;
  yellowMetricColor: $yellow-metric-color;
  redMetricColor: $red-metric-color;
  darkRedMetricColor: $dark-red-metric-color;
  totalPlaysColor: $total-plays-color;
  monetizedPlaysColor: $monetized-plays-color;
  suspiciousPlaysColor: $suspicious-plays-color;
  legiblePurpleColor: $legible-purple;
  colorBlack: $color-black;
  colorWhite: $color-white;
  colorPink: $color-pink;
  colorPurple: $color-purple;
  colorLightBlue: $color-light-blue;
  colorOffWhite: $color-off-white;
  colorLightGrey: $color-light-grey;
  pieColorOne: $color-sky-blue;
  pieColorTwo: $color-dark-blue;
  pieColorThree: $color-mid-blue;
  pieColorFour: $color-purple;
  pieColorFive: $color-mid-purple;
  pieColorSix: $color-light-blue;
  hanksTipRadius: $hanks-tip-radius;
  colorDarkGrey: $color-dark-grey;
  decisionBoxGreen: $decision-box-green;
  decisionBoxLightGreen: $decision-box-light-green;
  decisionBoxYellow: $decision-box-yellow;
  decisionBoxDarkYellow: $decision-box-dark-yellow;
  decisionBoxOrange: $decision-box-orange;
  decisionBoxRed: $decision-box-red;
  decisionBoxDarkRed: $decision-box-dark-red;
}
