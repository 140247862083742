@import "../../styles/constants.scss";

$toggle-transition-duration: 0.4s;
$chart-container-height: 300px;
$month-view-toggle-width: 33px;
$slider-radius: 16px;
$slider-space-from-edge: 2px;

.artist-play-count-wrapper {
  color: $color-dark-grey;
  font-size: $default-font-size;

  .artist-play-count-header {
    font-size: $large-header-font-size;
    font-weight: $font-weight-bold;
    padding-bottom: 1rem;
  }

  .artist-play-count-sub-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;

    .month-view-toggle-container {
      background-color: $color-off-white;
      border-radius: 4px;
      padding: 12px 16px;

      .month-view-label {
        font-size: $small-font-size;
        letter-spacing: $default-letter-spacing;
        padding-right: 10px;
      }

      .month-view-sub-container {
        display: inline-block;
        vertical-align: middle;

        .month-view-toggle {
          position: relative;
          display: inline-block;
          width: $month-view-toggle-width;
          height: $slider-radius + calc($slider-space-from-edge * 2);
        }

        .month-view-toggle input {
          opacity: 0;
          width: 0;
          height: 0;
        }

        .slider {
          background-color: $color-mid-grey;
          border-radius: 34px;
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          -webkit-transition: $toggle-transition-duration;
          transition: $toggle-transition-duration;
        }

        .slider:before {
          background-color: $color-white;
          border-radius: 50%;
          content: "";
          position: absolute;
          height: $slider-radius;
          width: $slider-radius;
          left: $slider-space-from-edge;
          bottom: $slider-space-from-edge;
          -webkit-transition: $toggle-transition-duration;
          transition: $toggle-transition-duration;
        }

        input:checked + .slider {
          background-color: $color-purple;
        }

        input:focus + .slider {
          box-shadow: 0 0 1px $color-purple;
        }

        input:checked + .slider:before {
          $transform-distance: ($month-view-toggle-width - $slider-radius) -
            calc($slider-space-from-edge * 2);

          -webkit-transform: translateX($transform-distance);
          -ms-transform: translateX($transform-distance);
          transform: translateX($transform-distance);
        }
      }
    }
  }

  .artist-play-count-chart-container {
    height: $chart-container-height;
    position: relative;

    .artist-play-count-loader-and-message-container {
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
      // Offset the sides to be centred on the graph itself
      left: 25px;
      bottom: 29px;

      &.loaded {
        // Don't allow hover/tooltip pop-up until the loading animation disappears
        pointer-events: none;
      }

      .artist-play-count-no-data-message {
        font-size: $default-header-font-size;
        background-color: $color-white;
      }
    }
  }

  .artist-play-count-legend {
    display: flex;
    justify-content: center;
    padding: 10px 0px;

    .legend-option {
      padding: 0px 12px;

      .legend-option-icon {
        @include artist-chart-legend-icon();
      }
    }
  }
}

.artist-play-count-tooltip {
  @include chart-tool-tip();

  .tool-tip-header {
    padding-bottom: 4px;
    font-weight: $font-weight-bold;
  }

  .tool-tip-row {
    display: flex;
    justify-content: space-between;
    padding: 4px 0px;

    .tool-tip-row-value {
      display: inline-block;
    }
  }

  .total-plays-row {
    font-weight: $font-weight-bold;
  }
}
