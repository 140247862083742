@import "../../styles/constants.scss";

.search-bar {
  background-color: $color-grey;
  border-radius: $default-card-radius;

  fieldset {
    border: none;
  }
}
