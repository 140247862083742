@import "../../styles/constants.scss";

$min-component-height: 393px;

.artist-total-plays-wrapper {
  color: $color-dark-grey;
  font-weight: $font-weight-bold;
  min-height: $min-component-height;
  overflow-wrap: break-word;
  
  .suspicious-plays-header {
    font-size: $large-header-font-size;
  }

  .suspicious-plays-total {
    font-size: $xxlarge-header-font-size;
  }

  .secondary-metric-container {
    display: inline-block;
    vertical-align: top;
    width: 50%;

    .secondary-metric-header {
      font-size: $default-header-font-size;
      padding-top: 3rem;
    }
    
    .secondary-metric-total {
      font-size: $xlarge-header-font-size;
    }
  }

  .percentage-metrics-container {
    padding-top: 12px;

    .metric-percentage {
      font-size: $large-header-font-size;
    }

    .advanced-metric-section {
      display: inline-block;
      font-size: $default-font-size;
      font-weight: normal;
      padding-right: 1rem;
    }
  }

  .artist-total-plays-loader {
    padding-top: 10rem;
  }
}
