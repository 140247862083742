@import "../../styles/constants.scss";

$min-component-height: 153px;
$button-circle-diameter: 16px;
$bar-graph-height: 48px;
$header-bottom-margin: 25px;

.artist-play-distribution-wrapper {
  width: 100%;
  min-height: 240px;
  height: fit-content;
  letter-spacing: $default-letter-spacing;

  .distribution-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: $header-bottom-margin;

    .distribution-title {
      color: $color-dark-grey;
      font-weight: $font-weight-bold;
      font-size: $large-header-font-size;
      margin: 0px;
    }

    .distribution-total-users {
      color: $color-mid-grey;
      font-weight: $font-weight-bold;
      text-align: end;
      margin: 0px;

      .distribution-total-users-number {
        font-size: 36px;
      }
    }
  }

  .distribution-buttons {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    margin-bottom: 20px;

    .distribution-button {
      @include purple-button();
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 7px 10px;
      margin-right: 15px;
      margin-bottom: 10px;

      &:not(.selected) {
        background-color: $color-grey;
        color: $color-dark-grey;

        &:hover {
          background-color: $color-lighter-light-grey;
        }
      }

      .circle {
        width: $button-circle-diameter;
        height: $button-circle-diameter;
        border-radius: 50%;
        margin-right: 10px;
        border: 1px solid $color-white;

        // Necessary to keep size from being potentially overridden
        min-width: $button-circle-diameter;
        min-height: $button-circle-diameter;
        max-width: $button-circle-diameter;
        max-height: $button-circle-diameter;
      }
    }
  }

  .striped-div {
    background-size: auto 100%;
    background-repeat: repeat no-repeat;
  }

  .distribution-bar-graph {
    height: $bar-graph-height;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 28px;
    position: relative;
    // Keeps the bars from ruining the rounded corners
    overflow: hidden;

    .divider {
      position: absolute;
      background-color: $color-white;
      width: 1px;
      height: 100%;
      // Set just below the top 3 bars
      z-index: 2;
    }

    .distribution-bar {
      height: 100%;
      position: absolute;

      &.unavailable-bar-message {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .distribution-tooltip {
    // Only exists as starting coordinate for child tooltip
    position: relative;
    height: 0px;
    width: 0px;

    .data-text {
      @include info-tool-tip();
      font-size: 16px;
      z-index: 10;
      // Ensures it's 10px below the bar graph
      top: $bar-graph-height + 10px;
      right: 0px;
      padding: 20px;
      font-weight: $font-weight-bold;
      white-space: nowrap;

      .tooltip-message {
        display: flex;
        flex-direction: row;

        span {
          // Add space to the left of the percentage if there is one
          margin-left: $width-of-a-space;
        }

        .tooltip-bar-type-message {
          font-weight: $default-font-weight;
          margin-left: $width-of-a-space;
        }
      }

      .z-score-and-percentile {
        color: $color-mid-grey;
        font-size: $default-font-size;
        font-weight: $default-font-weight;
        display: flex;
        flex-direction: row;
        margin-top: 5px;

        .percentile {
          margin-right: 20px;
        }
      }
    }
  }

  .unable-to-render-message {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    top: -$header-bottom-margin;
  }
}
