@import "../../styles/constants.scss";

$dashboard-header-font-size: 30px;

.dashboard-header-container {
  font-size: 15px;
  margin-top: 50px;

  .dashboard-header-upper-wrapper {
    left: 0px;
    position: fixed;
    top: 0px;
    width: 100vw;
    z-index: 20;

    .dashboard-header-upper-options-container {
      position: relative;
      width: 100%;
      height: 61px;

      .dashboard-header-options {
        align-items: center;
        background-color: $color-off-white;
        display: flex;
        justify-content: space-between;
        padding: 10px $default-page-width-padding;
        position: absolute;
        transition-duration: 0.4s;
        transition-property: opacity;
        width: calc(100% - ($default-page-width-padding * 2));

        @media (max-width: $dashboard-small-page-width-breakpoint) {
          width: calc(100% - ($small-page-width-padding * 2));
          padding-left: $small-page-width-padding;
        }

        &.dashboard-header-sticky {
          background-color: rgba($color-off-white, 0.9);
          border-bottom: 2px solid $color-off-white;
          transition-duration: 0.1s;

          .dashboard-header-text {
            .header-text-container {
              padding: 0px;
            }
          }
        }

        .entity-dropdown {
          display: flex;
          flex-direction: row;
          margin-right: 2rem;

          // Overwrite default dropdown label styling
          label {
            font-size: $default-header-font-size;
            font-weight: $default-font-weight !important;
          }

          .select-filter-dropdown:first-child {
            margin-right: 16px;
          }

          .header-apply-button {
            background-color: inherit;
            border: none;
            border-radius: $default-button-radius;
            font-size: $default-font-size;
            font-weight: $font-weight-slight-bold;
            line-height: $header-line-height;
            margin-left: 1rem;
            padding: 8px 16px;

            &.enabled {
              color: $color-purple;
              cursor: pointer;

              &:hover {
                background-color: $color-lighter-light-grey;
              }
            }

            &.disabled {
              color: $color-mid-purple;
              pointer-events: none;
            }
          }
        }

        .dashboard-header-history-logout {
          display: flex;
          align-items: center;

          .label-links {
            display: flex;
            padding-right: 20px;

            .label-link {
              padding: 0 10px;
              
              a {
                color: $color-purple;
                text-decoration: none;
              }
            }
          }

          .user-history-button-container {
            margin-right: 10px;

            .user-history-button {
              cursor: pointer;
              height: 30px;
              vertical-align: middle;
              width: 30px;
            }
          }
        }
      }
    }
  }

  .dashboard-header-text {
    color: $color-dark-grey;
    width: 100%;

    .header-text-container {
      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 1rem 0rem;

      .artist-header-container {
        width: 100%;

        .artist-header {
          display: flex;
          justify-content: flex-start;
          overflow-wrap: anywhere;
          width: 100%;

          .artist-header-title {
            width: fit-content;
          }

          .artist-id-text {
            display: flex;
            align-items: flex-end;
            font-size: $large-header-font-size;
            // Helps with vertical alignment
            line-height: $dashboard-header-font-size;
            padding-left: 1.5rem;
            width: fit-content;
          }
        }
      }

      .header-text {
        display: block;
        font-size: $dashboard-header-font-size;
        font-weight: bold;
        margin-right: $dashboard-header-font-size;
        overflow-wrap: anywhere;

        .song-header-artist-link {
          margin-left: $dashboard-header-font-size;

          a {
            // If it's a link, make it purple
            color: $color-purple;
            text-decoration: none;
          }

          .song-header-artist-link-arrow {
            height: 22px;
            margin-bottom: -2px;
            width: $dashboard-header-font-size;
          }
        }
      }
    }
  }

  .entity-loading-wrapper {
    background-color: rgba($color-black, 0.5);
    // Full view coverage
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0px;
    position: fixed;
    top: 0px;
    width: 100vw;
    // Above all other elements
    z-index: 20;
  }
}
