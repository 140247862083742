@import "../../styles/constants.scss";

$space-between-rows: 20px;
$space-between-pills: 10px;

.pill-list-container {
  display: flex;
  color: $color-dark-grey;
  flex-wrap: wrap;
  font-size: $default-font-size;
  font-weight: $default-font-weight;
  letter-spacing: $default-letter-spacing;
  line-height: 26px;
  margin-top: -$space-between-rows;
  width: 100%;

  .pill-list-option {
    align-items: center;
    background-color: $color-grey;
    border-radius: $default-button-radius;
    cursor: pointer;
    display: flex;
    justify-content: center;
    margin-right: $space-between-pills;
    margin-top: $space-between-rows;
    padding: 5px 20px;
    text-align: center;
    transition-duration: 0.2s;
    transition-property: color, background-color;
    width: fit-content;

    svg {
      margin-left: -4px;
      margin-right: 10px;
      stroke-width: 2px;
    }

    &:last-child {
      margin-right: 0px;
    }

    &.selected {
      background-color: $color-purple;
      color: $color-white;
    }
  }
}
