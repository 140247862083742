@import "../../styles/constants.scss";

$progress-ring-size: 190px;
$component-min-height: 350px;
$max-width-before-row-styling: 1465px;

.artist-suspicious-users-container {
  color: $color-dark-grey;
  letter-spacing: $default-letter-spacing;
  min-height: $component-min-height;

  .suspicious-users-header {
    font-weight: $font-weight-bold;
    font-size: $large-header-font-size;
    padding-bottom: 1rem;
  }

  .suspicious-users-left-section {
    display: inline-block;
    width: 60%;

    @media (min-width: $md-mobile-breakpoint) and (max-width: $max-width-before-row-styling) {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .suspicious-users-left-column {
      text-align: center;
      width: fit-content;

      .progress-ring-container {
        height: $progress-ring-size;
        width: $progress-ring-size;
      }

      .suspicious-users-secondary-metric {
        padding-top: 1rem;
        font-size: $default-font-size;
      }
    }
  }

  .suspicious-users-right-section {
    display: inline-block;
    vertical-align: top;
    width: 40%;

    @media (min-width: $md-mobile-breakpoint) and (max-width: $max-width-before-row-styling) {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .suspicious-users-right-column {
      width: fit-content;

      .suspicious-users-total {
        font-weight: $font-weight-bold;
        font-size: $xlarge-header-font-size;
        padding-top: 3rem;
        width: fit-content;
      }

      .users-total {
        font-weight: $font-weight-bold;
        font-size: $default-header-font-size;
        width: fit-content;
      }
    }
  }

  .suspicious-users-btn-container {
    display: flex;
    justify-content: center;
    padding-top: 3rem;

    .suspicious-users-btn {
      @include purple-button();
      width: fit-content;
    }

    .suspicious-users-btn.disabled {
      @include purple-button-disabled();
    }
  }

  .suspicious-users-loader {
    padding-top: 120px;
  }
}
