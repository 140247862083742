@import "../../styles/constants.scss";

$square-icon-right-margin: 8px;
$minimun-row-to-value-margin: 30px;

.tooltip-content {
  @include chart-tool-tip();

  p {
    margin-top: 0px;
    margin-bottom: 0.1rem;
  }

  .tooltip-row.large.tooltip-title {
    margin-top: 0px;
    margin-bottom: 0.3rem;
    font-weight: bold;
    font-size: $default-font-size;
  }

  .tooltip-lists {
    display: flex;
    height: fit-content;
    flex-direction: row;
    justify-content: space-between;

    .vertical-line {
      height: 260px;
      margin: 0px 10px;
      width: 2px;
      background-color: black;
    }

    .tooltip-list {
      list-style-type: none;
      margin-block-start: 0em;
      margin-block-end: 0em;
      padding-inline-start: 0px;
      width: 100%;

      .tooltip-row {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;

        div {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .row-name {
          margin-right: $minimun-row-to-value-margin;
        }

        .row-value {
          text-align: end;
        }
      }

      .tooltip-row.large {
        font-size: $default-font-size;

        .tooltip-total-count {
          font-weight: bold;
        }
      }

      li {
        display: flex;
        align-items: center;
        font-size: $small-font-size;

        svg {
          margin-right: $square-icon-right-margin;
        }
      }
    }
  }
}
