@import "../../styles/constants.scss";

.selection-slider-container {
  width: 100%;

  .selection-slider {
    border-radius: $default-card-radius;
    color: $color-mid-grey;
    display: flex;
    font-weight: $font-weight-slight-bold;
    font-size: $default-header-font-size;
    line-height: $header-line-height;
    letter-spacing: $default-letter-spacing;
    overflow: hidden;
    position: relative;
    width: 100%;

    &.with-textbox {
      margin-bottom: 22px;
    }

    .selection-slider-option {
      background-color: $color-grey;
      cursor: pointer;
      display: flex;
      justify-content: center;
      padding: 7px 0px;
      text-align: center;

      .selection-slider-option-text.selected {
        color: $color-white;
        transition-duration: 0.23s;
        transition-property: color;
        z-index: 2;
      }
    }

    .selection-slider-selected-box {
      height: 100%;
      background-color: $color-purple;
      border-radius: $default-card-radius;
      position: absolute;
      pointer-events: none;
      transition-duration: 0.28s;
      transition-property: left;
      z-index: 1;
    }
  }
}
