@import "../../styles/constants.scss";

.user-radar-chart-container {
  flex: 1;
  padding-bottom: 1rem;

  .user-radar-chart-legend {
    color: $color-white;
    padding-left: 4rem;
    z-index: 1;

    @media (max-width: $md-mobile-breakpoint) {
      padding-left: 1.5rem;
    }

    .chart-legend-option {
      align-items: center;
      display: flex;
      justify-content: space-between;
      border-radius: $default-button-radius;
      margin: 0.75rem 0rem;
      padding: 8px;
      width: fit-content;
      white-space: nowrap;
      width: 100px;

      .chart-legend-title {
        font-size: $small-font-size;
      }

      .toggle-chart-legend-option {
        cursor: pointer;
        float: right;
        width: 22px;
      }

      .toggle-chart-legend-option.inactive {
        transition: transform 0.5s;
        transform: rotate(45deg);
      }

      .toggle-chart-legend-option.active {
        transition: transform 0.5s;
      }
    }
  }

  .user-radar-chart {
    .user-radar-chart-responsive-container {
      fill: $color-light-grey;
      font-family: Helvetica Neue;
      font-size: $small-font-size;

      .user-radar-chart-tooltip {
        background-color: #333333;
        border-radius: $default-card-radius;
        color: $color-white;
        font-size: $small-font-size;
        padding: 1rem 2rem;

        .user-radar-chart-title {
          margin-bottom: 8px;
        }

        .tooltip-entry {
          padding: 8px 0px;

          .user-radar-chart-tooltip-label {
            display: inline-block;
            border-radius: $default-card-radius;
            margin-right: 1rem;
            padding: 5px 10px;
            text-align: center;
            width: 100px;
          }

          .user-radar-chart-tooltip-value {
            display: inline-block;
          }
        }
      }
    }
  }
}
