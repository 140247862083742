@import "../../styles/constants.scss";

.selection-boxes-container {
  width: 100%;

  .selection-boxes {
    display: flex;
    color: $color-mid-grey;
    font-size: $default-header-font-size;
    font-weight: $font-weight-slight-bold;
    letter-spacing: $default-letter-spacing;
    line-height: $header-line-height;
    width: 100%;

    .selection-box-option {
      background-color: $color-off-white;
      border-radius: $default-card-radius;
      cursor: pointer;
      display: flex;
      justify-content: center;
      margin-right: 30px;
      padding: 7px 30px;
      text-align: center;
      transition-duration: 0.2s;
      transition-property: color, background-color;

      &:last-child {
        margin-right: 0px;
      }

      &.selected {
        background-color: $color-purple;
        color: $color-white;
      }
    }
  }
}
