@import "../../styles/constants.scss";

$decision-box-padding: 40px;
$line-break-top-bottom-margin: 50px;
$section-label-font-size: 18px;
$section-label-line-height: 20px;

.decision-box-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;

  .decision-box-container {
    border: 1px solid $color-light-grey;
    letter-spacing: $default-letter-spacing;
    margin-top: 80px;
    padding: $decision-box-padding;
    width: calc(100% - ($decision-box-padding * 2));

    .decision-box-line-break {
      background-color: $color-light-grey;
      height: 1px;
      margin: $line-break-top-bottom-margin 0px;
      width: 100%;
    }

    .decision-box-presubmit {
      padding-top: 13px;
      padding-bottom: 43px;
    }

    .decision-box-submit {
      @include purple-button();
      font-weight: $default-font-weight;
      font-size: $default-header-font-size;
      line-height: $header-line-height;
      margin-top: $line-break-top-bottom-margin;
      margin-bottom: 15px;
    }

    .decision-box-section-container {
      display: flex;
      flex-direction: column;
      height: fit-content;
      position: relative;
      transition-duration: 0.5s;
      transition-property: max-height, opacity;

      &.hidden {
        max-height: 0;
        opacity: 0%;
        overflow: hidden;
      }

      &.visible {
        // Must be large enough to fit all possible fraud reasons in pill list
        max-height: 1500px;
        opacity: 100%;
      }

      .decision-box-section {
        display: flex;
        flex-direction: column;
        height: fit-content;

        &.fraud-reasons-section {
          flex-direction: row;
          margin-right: -$decision-box-reason-column-margin;
        }

        &.understanding-slider-section {
          max-width: 984px;
        }

        &.fraud-percentage-slider-section {
          max-width: 930px;
        }

        &.fraud-type-section {
          .decision-box-pill-list {
            &.no-margin {
              margin-bottom: 0px;
            }

            &.visible {
              // Must be large enough to fit all possible fraud reasons in pill list
              max-height: 1000px;
              opacity: 100%;
            }

            font-size: $default-header-font-size;
            font-weight: $font-weight-bold;
            line-height: 18px;
            height: fit-content;
            margin-bottom: $line-break-top-bottom-margin;
            max-height: 0px;
            opacity: 0%;
            overflow: hidden;
            transition-duration: 0.4s;
            transition-property: margin-bottom, opacity, max-height;
          }

          .decision-box-pill-list-label {
            display: flex;
            flex-direction: column;
            margin-top: 60px;

            span {
              font-weight: $default-font-weight;
              margin: 45px 0px 23px;
            }
          }

          .decision-box-pill-list-error {
            color: $red-metric-color;
          }
        }
      }

      .decision-box-section-label {
        color: $color-black;
        font-weight: $font-weight-bold;
        font-size: $section-label-font-size;
        line-height: $section-label-line-height;
        margin-bottom: 30px;

        .required {
          color: $red-metric-color;
        }

        .bonus-text {
          color: $color-mid-grey;
          font-weight: $default-font-weight;
        }
      }

      .decision-box-hanks-tip {
        display: flex;
        justify-content: flex-end;
        margin-left: -5px;
        position: absolute;
        top: -40px;
        width: 100%;
        z-index: 9;
      }
    }

    .decision-box-message {
      margin-top: 40px;
      &.error {
        color: $red-metric-color;
      }
    }
  }

  .decision-box-loading {
    position: absolute;
    pointer-events: none;
    z-index: 3;
  }
}
