@import "../../styles/constants.scss";

.user-comparison-modal-container {
  display: flex;
  background-color: #232323;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  position: absolute;
  width: 100%;

  .close-icon {
    color: $color-white;
    cursor: pointer;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
  }

  .modal-title {
    color: $color-white;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 1.5rem;
    padding: 1.5rem 4rem 0rem;

    @media (max-width: $md-mobile-breakpoint) {
      padding: 1.5rem;
    }
  }
}
