@import "../../styles/constants.scss";

$square-icon-fill-opacity: 50%;
$square-icon-right-margin: 0.75rem;

.stacked-chart-container {
  display: flex;
  width: 100%;
  height: 370px;
  flex-direction: column;
  align-items: center;

  .stacked-chart-title {
    align-self: flex-start;
    font-size: $default-header-font-size;
    font-weight: bold;
    margin: 7px 0px 10px;
  }

  .error-message {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .recharts-tooltip-wrapper {
    width: 50%;
    max-width: 275px;
  }

  .stacked-chart-legend-entry {
    color: black;
  }

  .recharts-legend-wrapper {
    background-color: white;
    font-size: $small-font-size;
    padding: 0rem 1rem 1rem;
    width: fit-content;

    .recharts-default-legend {
      width: fit-content;
    }

    .legend-list {
      list-style-type: none;
      margin-block-start: 0em;
      margin-block-end: 0em;
      padding-inline-start: 0px;
      padding-top: 2rem;
      width: 100%;
      
      .legend-row {
        align-items: center;
        display: flex;
        font-size: $default-font-size;
        line-height: 32px;

        svg {
          margin-right: $square-icon-right-margin;

          path {
            fill-opacity: $square-icon-fill-opacity;
          }
        }
      }
    }

    .recharts-legend-item.legend-item-0 {
      margin-top: 0rem;
    }
  }

  .stacked-chart-loader {
    align-content: center;
  }
}
