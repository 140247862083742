@import "../../styles/constants.scss";

$text-box-height: 98px;
$text-box-border-width: 1px;

.selection-text-box-wrapper {
  color: $color-mid-grey;
  font-weight: $font-weight-slight-bold;
  font-size: $default-font-size;
  height: fit-content;
  line-height: $header-line-height;
  letter-spacing: $default-letter-spacing;
  width: 100%;

  .selection-text-box-container {
    display: flex;
    flex-direction: column;
    height: fit-content;
    position: relative;
    transition-duration: 0.2s;
    transition-property: visibility, opacity;
    width: 100%;

    &.hidden {
      opacity: 0%;
      visibility: hidden;

      .selection-text-box {
        height: 0px;
        padding: 0px;
      }
    }

    &.visible {
      opacity: 100%;
      visibility: visible;

      .selection-text-box {
        height: $text-box-height;
      }
    }

    &.visible,
    &.hidden {
      .selection-text-box {
        transition-duration: 0.4s;
        transition-property: height;
      }
    }

    // Set placeholder text stylings
    .selection-text-box-placeholder {
      color: $color-mid-grey;
      font-size: $default-font-size;
      padding: 11.5px calc($default-font-size + $text-box-border-width);
      pointer-events: none;
      position: absolute;

      &.required span {
        color: $red-metric-color;
      }

      &.optional span {
        font-style: italic;
      }
    }

    .selection-text-box-error-message {
      color: $red-metric-color;
      padding-left: $default-font-size;
    }

    .selection-text-box {
      cursor: text;
      border: $text-box-border-width solid $color-mid-light-grey;
      border-radius: $default-card-radius;
      color: $color-neutral-grey-3;
      font-family: inherit;
      font-size: $default-font-size;
      padding: $default-font-size;
      resize: none;
      vertical-align: middle;

      // Ensure cursor appears over placeholder text on focus
      background: none;
      z-index: 2;

      &.error.required {
        border-color: $red-metric-color;
      }

      &:focus {
        border-color: $color-neutral-grey-3;
      }
    }
  }
}
