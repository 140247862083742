@import "../../styles/constants.scss";

.login-page {
  .login-form {
    border: 1px solid #ccc;
    margin: 250px auto auto;
    padding: 1rem;
    max-width: 300px;

    @media (max-width: $sm-mobile-breakpoint) {
      margin: 50% auto auto;
      min-width: 275px;
      width: 90%;
    }

    .login-header {
      text-align: center;
    }

    .login-form-content {
      display: inline-block;
      text-align: right;
      width: 100%;

      .login-field {
        margin: 1rem 0rem;

        .login-label {
          padding-right: 5px;
        }
      }

      .login-error {
        font-size: $default-font-size;
        margin-bottom: 1rem;
        text-align: center;
      }

      .login-submit {
        background-color: #006ee6;
        border: none;
        border-radius: 8px;
        color: white;
        cursor: pointer;
        padding: 0.7rem 1rem;
      }
    }
  }
}
