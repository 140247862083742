@import "../../styles/constants.scss";

.date-range-control-container {
  display: inline-block;
  white-space: nowrap;

  .date-range-control-icon {
    color: $color-purple;
    cursor: pointer;
    vertical-align: middle;
  }

  .date-range-control-text-container {
    display: inline;
    margin: 1.5rem 0.5rem 1rem;
    vertical-align: middle;

    .default-date-range-control-text {
      @include date-text-display();
    }

    .secondary-date-range-control-text {
      color: $color-purple;
    }
  }
}
