@import "../../styles/constants.scss";

.label-artist-container {
  .label-artist-logout {
    float: right;
    margin: 1rem;
  }

  .label-artist-footer {
    padding-bottom: 10px;
  }
}
