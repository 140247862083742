@import "../../styles/constants.scss";

$decision-box-reason-top-margin: 20px;

.selection-checkbox-list-container {
  color: $color-black;
  display: flex;
  flex-wrap: wrap;
  font-weight: $default-font-weight;
  font-size: $default-font-size;
  line-height: 18px;
  letter-spacing: $default-letter-spacing;
  margin-top: -$decision-box-reason-top-margin;
  width: 100%;

  &.row {
    flex-direction: row;
  }

  &.column {
    flex-direction: column;
  }

  .selection-checkbox-wrapper {
    margin-right: $decision-box-reason-column-margin;

    .selection-checkbox-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      margin-top: $decision-box-reason-top-margin;
      width: fit-content;

      &.textbox-version {
        margin-bottom: 15px;
      }

      label {
        cursor: pointer;
        display: flex;
        margin-right: 5px;
        margin-left: 3px;
      }
    }
  }
}
