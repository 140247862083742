@import "../../styles/constants.scss";

$min-column-spacing: 12px;
$min-icon-spacing: 12px;
// Hanks Tip affects the positioning logic,
// So we offset its diameter
$hanks-tip-diameter: calc($hanks-tip-radius * 2);
$min-component-height: calc(153px - $hanks-tip-diameter);

.artist-monthly-listeners-wrapper {
  color: $color-dark-grey;
  font-weight: $font-weight-bold;
  overflow-wrap: break-word;
  min-height: $min-component-height;
  height: fit-content;

  .monthly-listeners-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    top: -$hanks-tip-diameter;

    // Columns are to act like their own mini cards
    // In terms of padding between each other
    &:first-child {
      padding-right: $default-card-padding;
    }

    &:last-child {
      padding-left: $default-card-padding;
    }

    .monthly-listeners-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      // Keep things from breaking mid 1000px width
      flex-wrap: wrap;

      .monthly-listeners-icon {
        // If there is an icon, add some whitespace before the h3 title
        margin-right: $min-icon-spacing;

        path {
          fill: $color-white;
        }
      }

      h3 {
        font-size: $large-header-font-size;
        margin: 0px;
      }
    }

    .monthly-listeners-desc {
      font-size: $default-font-size;
      font-weight: $default-font-weight;
      letter-spacing: $default-letter-spacing;
      line-height: $header-line-height;
      padding-top: 1rem;
    }

    .monthly-listeners-number-count {
      font-size: $xxlarge-header-font-size;
      margin-top: 21px;
      margin-bottom: -$hanks-tip-diameter;
    }

    a {
      // Get rid of the underline
      text-decoration: none;

      .spotify-link-button {
        @include purple-button();
        width: fit-content;
        margin-bottom: calc(24px - $hanks-tip-diameter);
        font-size: $default-header-font-size;
        line-height: $header-line-height;
        display: flex;
        align-items: center;
        z-index: 1;

        // The open tab icon
        svg {
          margin-left: 0.75em;
          path {
            fill: $color-white;
          }
        }
      }

      &.disabled {
        // If no proper link, disable anchor tag
        pointer-events: none;

        .spotify-link-button {
          // As well as the button
          @include purple-button-disabled();
        }
      }
    }

    .spotify-green-background {
      // Cover the entire right half of the card
      // Beyond the confines of the container
      width: calc(100% + $default-card-padding);
      height: calc(100% + ($default-card-padding * 2) + $hanks-tip-diameter);

      // Position it to line up with edges
      position: absolute;
      top: -$default-card-padding;
      left: 0px;

      // Match the upper right radius of the card
      border-top-right-radius: $default-card-radius;

      // Spotify green background
      background-color: #1ed760;
    }

    :not(.spotify-green-background) {
      // Place everything above the background
      z-index: 1;
    }
  }
}
