@import "../../styles/constants.scss";

$slider-thumb-size: 16px;
$slider-height: 3px;
$webkit-thumb-offset: -6px;
$max-input-range-width: 2000;

// draws grey progress bar section on webkit browsers
@function makeLongShadow($color, $size) {
  $val: 5px 0 0 $size $color;

  // use arbitrary big number since width is dynamic (100%)
  // should be good enough for most screen sizes
  @for $i from 6 through $max-input-range-width {
    $val: #{$val}, #{$i}px 0 0 $size #{$color};
  }

  @return $val;
}

.slide-container {
  display: flex;
  width: 100%;

  .slider {
    align-items: center;
    appearance: none;
    background: none;
    cursor: pointer;
    display: flex;
    height: 100%;
    min-height: 30px;
    overflow: hidden;
    width: 100%;
    -webkit-appearance: none;

    &::-webkit-slider-runnable-track {
      background: $color-purple;
      content: "";
      height: $slider-height;
      pointer-events: none;
    }

    &::-webkit-slider-thumb {
      appearance: none;
      background: $color-purple;
      border-radius: 50%;
      cursor: pointer;
      height: $slider-thumb-size;
      width: $slider-thumb-size;
      box-shadow: makeLongShadow($color-light-grey, $webkit-thumb-offset);
      margin-top: $webkit-thumb-offset;
      -webkit-appearance: none;
    }

    &::-moz-range-thumb {
      background: $color-purple;
      border: none;
      border-radius: 50%;
      cursor: pointer;
      height: $slider-thumb-size;
      width: $slider-thumb-size;
    }

    &::-moz-range-track {
      background-color: $color-light-grey;
      width: 100%;
    }

    &::-moz-range-progress {
      background-color: $color-purple;
      height: $slider-height;
    }
  }
}
