@import "../../styles/constants.scss";

$chart-container-height: 330px;
$wrapper-min-height: $chart-container-height + 40px;

.artist-top-songs-wrapper {
  min-height: $wrapper-min-height;
  color: $color-dark-grey;

  .artist-top-songs-header {
    font-size: $large-header-font-size;
    font-weight: $font-weight-bold;
    padding-bottom: 1rem;
  }

  .artist-top-songs-chart-container {
    height: $chart-container-height;
  }

  .artist-play-count-legend {
    display: flex;
    font-size: $small-font-size;
    justify-content: center;
    padding-top: 12px;

    .legend-option {
      padding: 0px 12px;

      .legend-option-icon {
        @include artist-chart-legend-icon();
      }
    }
  }

  .artist-top-songs-tool-tip {
    background-color: $color-dark-grey;
    border-radius: 10px;
    color: $color-white;
    padding: 1rem;

    .tool-tip-header {
      font-weight: $font-weight-bold;
      padding-bottom: 2px;
    }

    .notice {
      color: $color-light-red;
      font-size: $default-font-size;
    }

    .tool-tip-row {
      padding: 2px 0px;

      .tool-tip-section {
        padding-right: 5px;
      }

      .tool-tip-plays-value {
        font-weight: $font-weight-bold;
      }
    }
  }

  .artist-top-songs-loader-or-error-message {
    // It's only visible when the height is the min/default
    height: $wrapper-min-height;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
