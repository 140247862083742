@import "../../styles/constants.scss";

$select-dark-grey: #120e17;
$select-label-vertical-padding: 8px;
$select-label-horizontal-padding: 14px;
$select-dropdown-max-height: 300px;

.select-filter-dropdown {
  display: flex;
  align-items: center;
  position: relative;

  label.dropdown-label {
    font-weight: $font-weight-bold;
    font-size: $default-header-font-size;
    letter-spacing: $default-letter-spacing;
    margin-right: 10px;
    line-height: $header-line-height;
  }

  .select-filter-select-container {
    .select-filter-select {
      background-color: $color-white;
      border: 1px solid $color-lighter-light-grey;
      border-radius: $default-card-radius;
      color: $select-dark-grey;
      cursor: pointer;
      display: inline-block;
      font-size: $default-header-font-size;
      padding: $select-label-vertical-padding $select-label-horizontal-padding;

      &.open,
      &:hover {
        background-color: $color-grey;
      }

      .select-filter-label {
        display: inline;
        padding-right: 1rem;
      }

      .select-filter-arrow {
        display: inline;
        pointer-events: none;

        svg {
          stroke: rgba($select-dark-grey, 0.5);
          stroke-width: 2;
        }
      }
    }

    .select-filter-dropdown-list {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 50px;
      background-color: $color-white;
      z-index: 13;
      padding: $select-label-vertical-padding 0px;
      border-radius: $default-card-radius;
      border: 1px solid $color-lighter-light-grey;
      // Keep the dropdown from being insanely tall
      max-height: $select-dropdown-max-height;
      overflow-y: auto;
      overflow-x: hidden;

      span {
        padding: $select-label-vertical-padding
          calc($select-label-horizontal-padding + 8px)
          $select-label-vertical-padding $select-label-horizontal-padding;
        white-space: nowrap;

        &:hover {
          background-color: $color-grey;
        }
      }
    }
  }
}
